<template>
  <div>
    <BlockUI
      id="loading"
      :message="$t('loading')"
      v-if="loading"
      html="<i class='fa fa-cog fa-spin fa-2x fa-fw'></i>"
    />

    <BlockItem :size="'1-2'">
      <span style="font-size: 16px; display: inline-block; margin-right: 10px">
        {{$t("date_range")}}:
      </span>
      <date-picker
        v-model="dateFilter"
        v-on:change="loadSafetyChecks()"
        range
      ></date-picker>
    </BlockItem>
    <BlockItem :size="'1-2'">
      <BlockInfoSelect
        :title="$t('country')+':'"
        :selected="countryCodes[0]"
        :values="countryCodes"
        @change-value="changeCountry"
      />
    </BlockItem>

    <div>
      <CRow>
        <CCol>
          <ListSafetyCheck
            v-if="!loading"
            v-bind:tableItems="listSafetyCheck"
          />
        </CCol>
      </CRow>
    </div>

    <div>
      <CButton
        color="success"
        size="lg"
        class="versions-button"
        @click="exportXlsx()"
        >Export XLSX</CButton
      >
      <br />
      <br />
    </div>
  </div>
</template>



<script>
import ListSafetyCheck from "../components/ListSafetyCheck.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import BlockInfoSelect from "../components/BlockInfoSelect";
import BlockItem from "../components/BlockItem";
export default {
  name: "SafetyChecks",
  components: {
    ListSafetyCheck,
    BlockInfoSelect,
    BlockItem,
    DatePicker,
  },
  data() {
    return {
      listSafetyCheck: [],
      loading: false,
      country: null,
      countryCodes: [],
      dateFilter: [],
    };
  },
  computed: {},
  async mounted() {
    var self = this;
    self.country = localStorage.getItem("userCountry")
      ? JSON.parse(localStorage.getItem("userCountry"))
      : "";
    try {
      self.countryCodes = [self.country];
      const res = await self.$http.get("apiConsole/console/getCountries");
      var countries = res.data.countries;
      self.countryCodes = [];
      countries.map((e) => {
        self.countryCodes.push(e.code);
      });
      self.changeCountry(self.countryCodes[0]);
    } catch (e) {
      if (e.response) {
        const res = e.response;
        console.log(res.data.message);
      }
      console.error(e);
    }

    self.loading = false;
    self.dateFilter = [
      new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      new Date(),
    ];

    this.loadSafetyChecks();
  },
  methods: {
    async loadSafetyChecks() {
      var self = this;
      if (self.dateFilter[0] && self.dateFilter[1]) {
        self.loading = true;
        var date1 = new Date(
          self.dateFilter[0].getTime() -
            self.dateFilter[0].getTimezoneOffset() * 60 * 1000
        );
        var date2 = new Date(
          self.dateFilter[1].getTime() -
            self.dateFilter[1].getTimezoneOffset() * 60 * 1000
        );

        const res = await self.$http.get(
          `apiConsole/console/safetyCheck/${
            self.country
          }/${date1.getTime()}/${date2.getTime()}`
        );
        self.listSafetyCheck = res.data.safetyCheck;

        self.loading = false;
      }
    },
    changeCountry(country) {
      var self = this;
      self.country = country;
      self.loadSafetyChecks();
    },
    async exportXlsx() {
      const self = this;
      var date1 = new Date(
        self.dateFilter[0].getTime() -
          self.dateFilter[0].getTimezoneOffset() * 60 * 1000
      );
      var date2 = new Date(
        self.dateFilter[1].getTime() -
          self.dateFilter[1].getTimezoneOffset() * 60 * 1000
      );
      const response = await self.$http(
        {
          url: `apiConsole/console/exportSafetyChecks/${
            self.country
          }/${date1.getTime()}/${date2.getTime()}`,
          method: "GET",
          responseType: "blob",
        } // important
      );

      if (!window.navigator.msSaveOrOpenBlob) {
        // BLOB NAVIGATOR
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `MyTruck_SAfetyChecks_${self.country}.xlsx`);
        document.body.appendChild(link);
        link.click();
      } else {
        // BLOB FOR EXPLORER 11
        const url = window.navigator.msSaveOrOpenBlob(
          new Blob([response.data]),
          `MyTruck_SAfetyChecks_${self.country}.xlsx`
        );
      }
    },
  },
};
</script>
<style>
</style>
